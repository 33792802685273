import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import './NavbarCustom.scss';

function NavbarCustom(props) {
  if (props.landingNav) {
    return (
      <Navbar style={{ position: 'absolute', zIndex: 1, width: '100%' }} variant={props.variant} expand={props.expand} className="glass">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                className="d-inline-block align-top"
                src={props.logo}
                alt="Logo"
                height={30}
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="mr-1">
                  <LinkContainer to="/">
                    <Nav.Link active={false}>Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <Nav.Link active={false}>About</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact">
                    <Nav.Link active={false}>Contact</Nav.Link>
                  </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar bg={props.bg} variant={props.variant} expand={props.expand} className={props.sticky ? "sticky-top mb-2 shadow-sm" : ""}>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                className="d-inline-block align-top p-0 m-0"
                src={props.logo}
                alt="Logo"
                height={30}
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="mr-1">
                  <LinkContainer to="/">
                    <Nav.Link active={false}>Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <Nav.Link active={false}>About</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact">
                    <Nav.Link active={false}>Contact</Nav.Link>
                  </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default NavbarCustom;
