import React from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import AboutPage from "./about";
import ContactPage from "./contact";
import PrivacyPage from "./privacy";
import TermsPage from "./terms";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import Logo from "../assets/whiteLogoNoBg.svg"

function App(props) {
  
  return (
      <Router>
        <>

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/privacy" component={PrivacyPage} />

            <Route exact path="/terms" component={TermsPage} />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bg="dark"
            textColor="light"
            size="sm"
            bgImage=""
            bgImageOpacity={1}
            description=""
            copyright="© 2022 Company"
            logo={Logo}
          />
        </>
      </Router>
  );
}

export default App;
