import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
//import ClientsSection from "./../components/ClientsSection";
//import VideoSection from "./../components/VideoSection";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
//import TestimonialsSection from "./../components/TestimonialsSection";
//import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";
//import { useAuth } from '../util/auth';
import Logo from "../assets/whiteLogoNoBg.svg"
import GoRunMockup from "../assets/gorun-mockup.png"
import CTAImage from "../assets/cta-bg-image.jpeg"

function IndexPage(props) {
  //const auth = useAuth();
  return (
    <>

      <NavbarCustom
        buttonColor="compliment"
        variant="dark"
        expand="md"
        logo={Logo}
        landingNav
      />
      <HeroSection
        bg="dark"
        textColor="white"
        size="md"
        bgImage={"https://images.unsplash.com/photo-1464983308776-3c7215084895?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80"}
        bgImageOpacity={0.5}
        title="Your virtual run test trainer"
        subtitle="Timerr walks you through your run tests like a coach and provides valuable analytics about your performance."
        image={GoRunMockup}
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/"
      />
      <FeaturesSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="Timerr empowers athletes to create run tests, practice them, and analyze their performance."
      />
      <NewsletterSection
        bg="dark"
        textColor="light"
        size="md"
        bgImage={CTAImage}
        bgImageOpacity={0.5}
        title="Stay in the know"
        subtitle="Receive our latest articles and developments"
        buttonText="Notify me"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="Success, check your inbox and confirm!"
      />
    </>
  );
}

export default IndexPage;


/*
    <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
    />
*/

/*
      <VideoSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Your title here"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/z9Ul9ccDOqE"
      />
*/

/*
<TestimonialsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
*/

/*
<NewsletterSection
  bg="white"
  textColor="dark"
  size="sm"
  bgImage=""
  bgImageOpacity={1}
  title="Stay in the know"
  subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
*/

/*
<CtaSection
        bg="dark"
        textColor="light"
        size="md"
        bgImage={require("../assets/cta-bg-image.jpeg")}
        bgImageOpacity={0.5}
        title="Demo Timerr Now"
        subtitle=""
        buttonText="View Demo"
        buttonColor="primary"
        buttonPath={auth.user ? '/dashboard/home' : 'auth/signup'}
      />
*/

/*

<ClientsSection
bg="dark"
textColor="light"
size="sm"
bgImage=""
bgImageOpacity={1}
title="Some of the best use Timerr:"
subtitle=""
/>

*/