import React from "react";
import NavbarCustom from "../components/NavbarCustom";
import ContactSection from "./../components/ContactSection";
import Logo from "../assets/colorLogoNoBg.svg"

function ContactPage(props) {
  return (
    <>
    <NavbarCustom
      bg="light"
      variant="light"
      expand="md"
      logo={Logo}
    />
    <ContactSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Contact Us"
      subtitle=""
      buttonText="Send message"
      buttonColor="primary"
      showNameField={true}
    />
    </>
  );
}

export default ContactPage;
