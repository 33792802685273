import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import DownloadButton from "../assets/download-light.svg"
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="mt-3 container-custom">
          <Col lg={6} className="text-center text-lg-left">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
            <LinkContainer to={props.buttonPath}>
                <Image src={DownloadButton} width={175}/>
            </LinkContainer>
          </Col>
          <Col className="offset-lg-1 img-container">
            <figure className="HeroSection__image-container mt-2">
              <Image src={props.image} fluid={true} />
            </figure>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default HeroSection;
