import React from "react";
import NavbarCustom from "../components/NavbarCustom";
import PrivacySection from "../components/PrivacySection"
import Logo from "../assets/colorLogoNoBg.svg"

function PrivacyPage(props) {
  return (
    <>
    <NavbarCustom
      bg="light"
      variant="light"
      expand="md"
      logo={Logo}
    />
    <PrivacySection/>
    </>
  );
}

export default PrivacyPage;
