import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";
import Logo from "../assets/colorLogoNoBg.svg"

function AboutPage(props) {
  return (
    <>
    <NavbarCustom
        bg="light"
        variant="light"
        expand="md"
        logo={Logo}
      />
      <HeroSection2
        bg="primary"
        textColor="white"
        size="lg"
        bgImage="https://images.unsplash.com/photo-1613330652294-1d4dae5affe7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
        bgImageOpacity={0.2}
        title="We help you get fast."
        subtitle="We are building an app to helpt athletes practice their conditioning runs on their own so they can be in the best possible condition for their season."
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Who are we?"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
