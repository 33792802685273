import React from "react";
import NavbarCustom from "../components/NavbarCustom";
import TermsSection from "../components/TermsSection"
import Logo from "../assets/colorLogoNoBg.svg"

function TermsPage(props) {
  return (
    <>
    <NavbarCustom
      bg="light"
      variant="light"
      expand="md"
      logo={Logo}
    />
    <TermsSection/>
    </>
  );
}

export default TermsPage;
