import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AspectRatio from "./AspectRatio";
import Image from "react-bootstrap/Image";
import Avatar from "./Avatar";
import "./TeamBiosSection.scss";
import ProfileImg from '../assets/headshot.JPG'

function TeamBiosSection(props) {
  const items = [
    {
      headerImage: "https://images.unsplash.com/photo-1616528279874-b3d3b50ab6c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80",
      avatarImage: ProfileImg,
      name: "Paul Sutton",
      role: "Founder",
      bio:
        "Paul is a rising senior computer science major @Temple University who enjoys running, weight lifting, and skiing. His girlfriend, a member of a D1 lacrosse team, largely provided the inspiration for timerr after complaining about the difficulty of timing laps while practicing conditioning runs over summer break.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              //xs={12}
              md={6}
              //lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="shadow">
                <AspectRatio ratio={3 / 1}>
                  <Image src={item.headerImage} fluid={true} />
                </AspectRatio>
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <div className="TeamBiosSection__avatar-wrapper">
                    <Avatar
                      src={item.avatarImage}
                      alt={item.name}
                      size="150px"
                    />
                  </div>
                  <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                  <small>{item.role}</small>
                  <Card.Text className="mt-4">{item.bio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
