import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./FeaturesSection.scss";
import CreateRunMockup from "../assets/createrun-mockup.png"
import TimerMockup from "../assets/timer-mockup.png"
import RunLogMockup from "../assets/runlog-mockup.png"

function FeaturesSection(props) {
  const items = [
    {
      title: "Create runs",
      description:
        "Create custom runs or add a run by code. Timerr makes it easy to share a custom run with your entire team.",
      image: CreateRunMockup,
    },
    {
      title: "Run along with Timerr",
      description:
        "Timerr guides you through your run just like a coach would, announcing each important marker so you know exactly where you are in your run.",
      image: TimerMockup,
    },
    {
      title: "Evaluate your performance",
      description:
        "See a lap by lap breakdown of your run to know exactly which parts of the run you passed, and which parts of the run you can improve.",
      image: RunLogMockup,
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <div className="FeaturesSection__features">
          {items.map((item, index) => (
            <Row className="align-items-center" key={index}>
              <Col md="4">
                <SectionHeader
                  title={item.title}
                  subtitle={item.description}
                  spaced={true}
                  size={3}
                  className="text-center text-lg-left"
                />
              </Col>
              <Col>
                <figure className="FeaturesSection__image-container">
                  <Image src={item.image} alt={item.title} fluid={true}/>
                </figure>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
